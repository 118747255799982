<template>
  <v-layout column fill-height>
    <v-flex shrink>
      <v-layout align-center px-2 row>
        <w-search-input
          clearable
          :label="$t('actions.search')"
          prepend-inner-icon="search"
          :value="search"
          @input="launchSearch($event)"
        />
        <v-spacer />
        <w-btn icon="add" @click="openApplicationForm()">{{
          $t("sa.applications.add_app")
        }}</w-btn>
      </v-layout>
    </v-flex>
    <v-flex style="position: relative">
      <v-layout fill-height>
        <div v-show="loadings > 0" class="loader-container">
          <v-progress-circular color="primary" indeterminate size="50" width="6" />
        </div>
        <v-flex scroll-y>
          <v-list two-line>
            <v-list-tile
              v-for="application in applications"
              :key="application.id"
              @click="openApplicationForm(application)"
            >
              <v-list-tile-avatar>
                <v-img :src="application.logo" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ application.title }}</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  application.description
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <w-btn-delete ml-2 mini @click.stop="deleteApplication(application)" />
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex shrink>
      <v-layout row>
        <v-flex xs12 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
          <w-pagination
            :item-name="$tc('sa.applications.applications', 2).toLowerCase()"
            :page-number="page"
            :rows-per-page="rows"
            :total-items="total"
            :total-visible="5"
            @update:page-number="modifyPage($event)"
            @update:rows-per-page="modifyRows($event)"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import SuperAdminModuleGuard from "@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard";

const ROUTE = 'sa-applications-list'

export default {
  name: "ApplicationsList",
  mixins: [SuperAdminModuleGuard],
  props: {
    page: {
      default: 1,
      required: false,
      type: Number
    },
    rows: {
      default: 10,
      required: false,
      type: Number
    },
    search: {
      default: null,
      required: false,
      type: String
    }
  },
  data: function () {
    return {
      applications: [],
      listApplications: null,
      loadings: 0,
      searchTimeout: null,
      total: 0
    };
  },
  computed: {
    queryFilters: function () {
      return `${this.page}/${this.rows}/${this.search}`
    }
  },
  watch: {
    queryFilters: {
      handler: function (newFilters, oldFilters) {
        if (newFilters == oldFilters) {
          return
        }

        const newSearch = newFilters.split('/')[2]
        const oldSearch = oldFilters.split('/')[2]

        if (newSearch != oldSearch) {
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = null
          }

          this.searchTimeout = setTimeout(
            function () {
              this.loadApplications()
            }.bind(this),
            1000
          );
        } else {
          this.loadApplications()
        }
      }
    }
  },
  mounted: function () {
    this.loadApplications();
  },
  methods: {
    deleteApplication: function (application) {
      return this.$dialog
        .warning({
          text: this.$t("sa.applications.actions.are_you_sure", {
            name: application.name,
          }),
          title: this.$t("sa.applications.actions.delete"),
          actions: {
            false: this.$t("actions.no"),
            true: this.$t("actions.yes"),
          },
        })
        .then((res) => {
          if (res) {
            return this.service.deleteApplication(application.id).then(() => {
              this.appEventBus.emit(
                this.appEvents.SNACKBAR_SUCCESS,
                this.$t("sa.applications.application_deleted")
              );
              return this.loadApplications();
            });
          }
        });
    },
    launchSearch: function (search) {
      const query = this.$route?.query ? { ...this.$route.query } : {}
      if (search) {
        query.search = search
      } else {
        delete query.search
      }
      delete query.page
      this.appService.goTo({
        name: ROUTE,
        query,
        replace: true
      })
    },
    loadApplications: function () {
      this.loadings++;

      const query = {
        page: this.page,
        perPage: this.rows,
        search : this.search
      }

      this.service
        .listApplications(query)
        .then(({ data: applications, pagination }) => {
          this.total = pagination.total;

          this.applications = applications;
        })
        .finally(() => {
          this.loadings--;
        });
    },
    openApplicationForm: function (application) {
      this.appService.goTo({
        name: "sa-applications-form",
        params: {
          applicationId: application?.id ?? "create",
        },
      });
    },
    modifyPage: function (pageNumber = 1) {
      const query = this.$route?.query ? { ...this.$route.query } : {}
      if (pageNumber == 1) {
        delete query.page
      } else {
        query.page = pageNumber
      }
      this.appService.goTo({
        name: ROUTE,
        query,
        replace: true
      })
    },
    modifyRows: function (rows = 10) {
      const query = this.$route?.query ? { ...this.$route.query } : {}
      if (rows == 1) {
        delete query.rows
      } else {
        query.rows = rows
      }
      delete query.page
      this.appService.goTo({
        name: ROUTE,
        query,
        replace: true
      })
    }
  },
};
</script>
